import React, {Component} from 'react';
import './App.css';
import Header from "./components/Header";
import Landing from "./pages/Landing";
import {Route, Switch} from "react-router-dom";
import SearchPage from "./pages/SearchPage";
import MappingData from "./pages/MappingData";
import ChesireMerseyside from "./pages/ChesireMerseyside";
import KentMedway from "./pages/KentMedway";
import KentMedway2 from "./pages/KentMedway2";
import BCPS from "./pages/BCPS";
import PracticePage from "./pages/PracticePage";
import RoutePage from "./pages/RoutePage";
import RoutificProjRoute from "./pages/RoutificProjRoute";
import RoutificProjDriver from "./pages/RoutificProjDriver";
import Routific from "./pages/Routific";
export default class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null
        };
    }

    setUser = user => {
        this.setState({
            user: user
        });
    };

    render() {
        return (
            <div>
                <Header setUser={this.setUser} user={this.state.user}/>
                <Switch>
                    <Route exact path="/" render={() => <Landing user={this.state.user} setUser={this.setUser}/>}/>
                    <Route exact path="/dashboard" render={() => <SearchPage setUser={this.setUser}/>}/>
                    <Route path="/dashboard/:id" component={PracticePage}/>}/>
                    <Route exact path="/mappingdata" render={() => <MappingData setUser={this.setUser}/>}/>
                    <Route exact path="/chesiremerseyside" render={() => <ChesireMerseyside setUser={this.setUser}/>}/>
                    <Route exact path="/bcps" render={() => <BCPS setUser={this.setUser}/>}/>
					<Route exact path="/KentMedway1" render={() => <KentMedway setUser={this.setUser}/>}/>
					<Route exact path="/KentMedway2" render={() => <KentMedway2 setUser={this.setUser}/>}/>
                    <Route exact path="/routing" render={() => <Routific setUser={this.setUser}/>}/>
                    <Route path="/projroute" component={RoutificProjRoute}/>}/>
                    <Route path="/projdriver" component={RoutificProjDriver}/>}/>
                    <Route path="/route/:id" component={RoutePage}/>}/>
                </Switch>
            </div>
        );
    }
}
