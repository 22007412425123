import React, {Component} from "react";
import Navbar from "react-bootstrap/Navbar"
import {Auth} from "aws-amplify"
import Nav from "react-bootstrap/Nav";
import logo from "../gp-liaison-services-logo.png";
import {withRouter} from "react-router-dom";

class Header extends Component {
    signOut = () => {
        this.props.history.push("/");
        this.props.setUser(null);
        Auth.signOut().catch(err => console.log(err));
    };

    componentDidMount() {
        if (this.props.user === null) {
            Auth.currentAuthenticatedUser()
                .then(user => {
                    this.props.setUser(user.username);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    render() {
        return (
            <Navbar collapseOnSelect expand='md' bg='dark' variant='dark'>
                <img src={logo} alt='GP Liaison Services' className='App-logo'/>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id='responsive-navbar-nav'>
                    <Nav className='ml-auto'>
                        <Nav.Link href='https://www.gp-liaison.com'>Home</Nav.Link>
                        <Nav.Link href='https://www.gp-liaison.com/about'>About Us</Nav.Link>
                        <Nav.Link href='https://www.gp-liaison.com/what-we-do'>What We Do</Nav.Link>
                        <Nav.Link href='https://www.gp-liaison.com/case-studies'>Case Studies</Nav.Link>
                        <Nav.Link href='https://www.gp-liaison.com/contact'>Contact</Nav.Link>
                        {this.props.user && <Nav.Link href='/'>Dashboard</Nav.Link>}
                        {this.props.user && <Nav.Link onClick={this.signOut}>Sign Out</Nav.Link>}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default withRouter(Header);