import React, {Component} from "react";
import Spinner from "react-bootstrap/Spinner";

export default class Loading extends Component {
    render() {
        return (
            <div className='typeahead'>
                <Spinner animation='border'/>
            </div>
        );
    }
}