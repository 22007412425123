import React, {Component} from "react";
import Table from "react-bootstrap/Table";
import {API, Auth} from "aws-amplify";
import Loading from "../components/Loading";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GoogleMaps from "../components/GoogleMaps";
import Button from "react-bootstrap/Button";
import {createBrowserHistory} from "history";
import {Link} from "react-router-dom";
import BackButton from "../components/BackButton";

 
let title = {
    fontSize: "14",
    fontStyle: "bold",
    display: "block"
}

let field = {
    fontSize: "10",
}
let infobox = {
    textAlign: "left"
}


export default class RoutificProjRoute extends Component {

    constructor(props) {
        super(props);
        this.state = {
            routeData: [] ,
            routeData1: {} ,
            projectid:'',
            loadingdata: false
        }
        this.history = createBrowserHistory();

        this.loadingdata = true;
        console.log('this.props.location.state:',this.props.location.state)    
    this.routeData1 =   this.props.location.state.route;
    this.projectid =   this.props.location.state.id;
    console.log('routeData:',this.routeData1)    
    this.state.routeData = this.routeData1[0]?.solution?.visits;

    
            
    //this.loadingdata = false;

    }


    async componentDidMount() {
       
        const data = await Auth.currentSession();
        const token = data.getIdToken().getJwtToken();
        const apiName = 'Routific_API';
        const path = '/getupdatedroute?id='+ this.projectid;
        const params = {
            headers: {
                Authorization: token
            } 
        };

        var projectRouteData = [];
        API.get(apiName, path,  params)
            .then((response) => {       
                projectRouteData = response;
                console.log(projectRouteData);
                if(projectRouteData.length > 0)
                    this.state.routeData = projectRouteData[0]?.solution?.visits;
                this.setState({
                    loadingdata: false 
                });
            })
    }


    render() {
        return (
            
            <div className="container">
                <h2>Visit Details</h2>
                <div style={{float:'right', padding:'20px 0px'}}><BackButton/></div>
                {this.state.loadingdata ? <Loading/> : ''}
                {!this.state.loadingdata && (this.state.routeData == null || this.state.routeData?.length === 0) ? <h5>No results found</h5> :
                    <Table responsive='sm'>
                        <thead>
                        <tr>
                            <th>Arrival Time</th>
                            <th>Finish Time</th>
                            <th>Start Time</th>                            
                            <th>End Time</th>
                            <th>Idle Time</th>
                            <th>Location</th> 
                        </tr>
                        </thead>

                        <tbody>
                                {this.state.routeData?.map((json,idx) => {
									 
                                        return (
                                            <tr key={idx}>
                                                <td>{json.arrival_time}</td>
                                                <td>{json.finish_time}</td>
                                                <td>{json.start_time}</td>
                                                <td>{json.end}</td>
                                                <td>{json.idle_time}</td>
                                                <td>{json.location?.name}, {json.location?.address}</td>
                                            </tr>
                                        )
                                   

                                })}
                            </tbody>

                    </Table>
                }
            
            </div>
        );
    } 
}
