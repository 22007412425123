import React, {Component} from "react";
import {
    ConfirmSignIn,
    ForgotPassword,
    RequireNewPassword,
    SignIn,
    SignUp,
    VerifyContact,
    withAuthenticator
} from "aws-amplify-react";
import Amplify, {API, Auth} from "aws-amplify";
import awsconfig from "../aws-exports";
import {Link} from "react-router-dom";
import Container from "react-bootstrap/Container";
import map from "../images/map.png"
import health from "../images/health.png"
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar, faExchangeAlt  } from '@fortawesome/free-solid-svg-icons'
import { faAmbulance, faRoute  } from '@fortawesome/free-solid-svg-icons'

console.log('awsconfig :',awsconfig)
Amplify.configure(awsconfig);

Amplify.configure({
    
    API: {
        endpoints: [
            {
                name: "medapi",
                endpoint: "https://tm6dr3b4y1.execute-api.eu-west-2.amazonaws.com" 
            },
			  {
                name: "MedDataApi_DynamoDB",
                endpoint: "https://3xxji5ygma.execute-api.eu-west-2.amazonaws.com/beta" 
            },
            {
                name: "Routific_API", 
                endpoint: "https://mjba00q612.execute-api.eu-west-2.amazonaws.com/staging"
            },
            {
                name: "quicksight", 
                endpoint: "https://vznunkyk0m.execute-api.eu-west-2.amazonaws.com"
            }
            ,
            {
                name: "cmdashboard", 
                endpoint: "https://42b9eyfn63.execute-api.eu-west-2.amazonaws.com"
            }
        ]
    }
});
 
let faIcons = {
    fontSize: "200px"
}
class Landing extends Component {
	
	    constructor(props) {
        super(props);

    this.state = {
		userGroup:''
	};
       


    }
	
    componentDidMount() {
        console.log(this.props.user);
        if (this.props.user === null) {
            Auth.currentAuthenticatedUser()
                .then(user => {
					console.log('user detils:', user);
                    this.props.setUser(user.username);
                })
                .catch(err => {
                    console.log(err);
                });
		}
		
			Auth.currentSession()
            .then(session => {
				var groups = session.idToken.payload["cognito:groups"];
				if(groups.length >0){
					console.log(groups.length)
					var strVal = '';
					for(var i=0; i < groups.length; i++){
						strVal = strVal + "," + groups[i];
					}
					console.log(strVal)
					this.setState({
                            userGroup: strVal
                        }) 
						 
				}
						// {this.state.userGroup == '' &&
				// <div><h3>You are not part of any user group. Please contact administrator.</h3></div>
			// }
						
				
			}) .catch(err => {
                    console.log(err);
                });				
        
    }

    render() {
        return (
		<div className="container">
			
			 
				<Container className="mt-5">
					<CardDeck id="dashboard" style={{display:'block'}}>
						<Card className="text-center mb-auto card-format">
							<Link to="/dashboard">
								<Card.Header>
									<h5 className="mb-0">Transport Routes and Phlebotomy Data</h5>
								</Card.Header>
								<Card.Body style={{maxHeight:276}} className="mx-auto" ><FontAwesomeIcon color="black" style={faIcons} icon={faAmbulance}/></Card.Body>
								{/*<Card.Img style={{height:276}} className="mx-auto" src={health}/>*/}
							</Link>
						</Card>

						  <Card className="text-center mb-auto card-format">
							<Link to="/routing">
								<Card.Header>
									<h5 className="mb-0">Routing Pilot</h5>
								</Card.Header>
								<Card.Body style={{maxHeight:276}} className="mx-auto" ><FontAwesomeIcon color="black" style={faIcons} icon={faRoute}/></Card.Body>
								
							</Link>
						</Card>  
			
			{this.state.userGroup.indexOf('WestMidlands')>-1 &&
						<Card className="text-center mb-auto card-format">
							<Link to="/mappingdata">
								<Card.Header>
									<h5 className="mb-0">Specimen Quality Data</h5>
								</Card.Header>
								<Card.Body style={{maxHeight:276}} className="mx-auto" ><FontAwesomeIcon color="black" style={faIcons} icon={faChartBar}/></Card.Body>
								{/*<Card.Img style={{height:276}} className="mx-auto" src={health}/>*/}
							</Link>
						</Card>
			}			
			{this.state.userGroup.indexOf('ChesireMerseyside')>-1 &&
						<Card className="text-center mb-auto card-format">
							<Link to="/chesiremerseyside">
								<Card.Header>
									<h5 className="mb-0">Chesire Merseyside Dashboard</h5>
								</Card.Header>
								<Card.Body style={{maxHeight:276}} className="mx-auto" ><FontAwesomeIcon color="black" style={faIcons} icon={faChartBar}/></Card.Body>
								{/*<Card.Img style={{height:276}} className="mx-auto" src={health}/>*/}
							</Link>
						</Card> 
					
			}
			  
			{this.state.userGroup.indexOf('Medway')>-1 &&	
						<Card className="text-center mb-auto card-format">
							<Link to="/KentMedway2">
								<Card.Header>
									<h5 className="mb-0">Kent and Medway Pilot Dashboard</h5>
								</Card.Header>
								<Card.Body style={{maxHeight:276}} className="mx-auto" ><FontAwesomeIcon color="black" style={faIcons} icon={faChartBar}/></Card.Body>
								{/*<Card.Img style={{height:276}} className="mx-auto" src={health}/>*/}
							</Link>
						</Card>
			}{this.state.userGroup.indexOf('Medway')>-1 &&		
						<Card className="text-center mb-auto card-format">
							<Link to="/KentMedway1">
								<Card.Header>
									<h5 className="mb-0">Kent and Medway CCG Dashboard</h5>
								</Card.Header>
								<Card.Body style={{maxHeight:276}} className="mx-auto" ><FontAwesomeIcon color="black" style={faIcons} icon={faChartBar}/></Card.Body>
								{/*<Card.Img style={{height:276}} className="mx-auto" src={health}/>*/}
							</Link>
						</Card>
			}
					
					</CardDeck>
				</Container> 
			 	
		</div>
        );
    }
}

const myTheme = {
    button: {backgroundColor: '#343a40'}
};

export default withAuthenticator(Landing, false, [
    <SignIn/>,
    <SignUp/>,
    <ConfirmSignIn/>,
    <VerifyContact/>,
    <ForgotPassword/>,
    <RequireNewPassword/>
], null, myTheme)
