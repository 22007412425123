import React, {Component} from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Loading from "./Loading";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoute  } from '@fortawesome/free-solid-svg-icons';

let faIcons = {
    fontSize: "20px"
}
export default class RoutificProjects extends Component {
    render() {
        return (
            <div>
                
                {this.props.data.length === 0 ? <h5>No results found</h5> :
                    <Table responsive='sm'>
                        <thead>
                        <tr>
                            <th>Project Name</th>
                            <th>Project Date</th>
                            <th>Project Status</th>
                            <th>Vehicle Count</th>
                            <th>Travel Time</th>
                            <th>Distance</th>
                        </tr>
                        </thead>

                        <tbody>
                                {this.props.data.map(json => {
									 
                                        return (
                                            <tr key={json._id}>
                                                <td>
                                                    <Link to={{pathname:`/projroute`, state:{id:json._id,route:json.route}}}>{json.name}</Link> &nbsp;&nbsp;&nbsp;
                                                    <Link to={{pathname:`/projdriver`, state:json._id}}><FontAwesomeIcon color="black" style={faIcons} icon={faRoute}/></Link>
                                                </td>
                                                <td>{json.date}</td>
                                                <td>{json.status}</td>
                                                <td>{json.vehicleCount}</td>
                                                <td>{json.travelTime}</td>
                                                <td>{json.distance?.toFixed(2)}</td>
                                            </tr>
                                        )
                                   

                                })}
                            </tbody>

                    </Table>
                }
            </div>
        );
    }
}
