/* App.js */

import { render } from "react-dom";
import { Chart } from "react-google-charts";
var React = require('react');
var Component = React.Component;
//extends Component {

var specimenStyle = {
    borderBottom:1,
    borderBottomStyle: "solid",
    paddingBottom:10,
    borderColor: "rgb(227, 225, 225)"

}

export default class SpecimenCount extends Component {
    render() {
        return(
            <div className={"table-responsive-sm my-pretty-chart-container"} >
                <h3 style={specimenStyle}>Specimen Sample Data</h3>
            <Chart
                chartType="ColumnChart"
                data={this.props.data}
                width="100%"
                height="400px"
                legendToggle
            />
        </div>)
    }

}


// module.exports = App;
//
// {this.props.data.map(json => {
//     return <tr key={json.Request_Date}>
//         <td>{json.Request_Date}</td>
//         <td>{json.SpecimenCount}</td>
//     </tr>
// })}
//








// export default class SpecimenCount extends Component {
//     render() {
//         return (
//             <Card className='text-center'>
//                 <Card.Header as='h3'>Specimen Count</Card.Header>
//                 <Card.Body className='specimen-count'>
//                     {this.props.data.length === 0 ? <h5>No results found</h5> :
//                         <Table responsive='sm'>
//                             <thead>
//                             <tr>
//                                 <th>Request Month</th>
//                                 <th>Total Tests Ordered</th>
//                             </tr>
//                             </thead>
//                             <tbody>
//
//
//     }
// }
