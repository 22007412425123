import React, {Component} from "react";
import { useHistory } from "react-router-dom";
export const BackButton = () => {
    let history = useHistory();
    return (
        <>
            <button style={{float:"right"}} onClick={() => history.goBack()}>Back</button>
        </>
    );
};
export default BackButton;
//onClick={this.context.router.history.goBack}
