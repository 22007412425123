

import React from 'react';
import GoogleMapReact from 'google-map-react';

const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%'
}


const GoogleMaps = ({ latitude, longitude }) => {
    const renderMarkers = (map, maps) => {
        let marker = new maps.Marker({
            position: { lat: latitude, lng: longitude },
            map,
            title: 'Hello World!'
        });
        return marker;
    };

    return (
        <div style={{ height: '30vh', width: '100%' , position: 'relative'}}>
        <div style={{ position: "absolute", left: "0", right: "0", top: "0", bottom: "0", width: '500px'}}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyB1zfo2QrIW15N3Oo3LOP-PE_1dRG7PCO8' }}
                defaultCenter={{ lat: latitude, lng: longitude }}
                defaultZoom={16}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            >
            </GoogleMapReact>
        </div>
        </div>
    );
};

export default GoogleMaps;

