import React, {Component} from "react";
import {API, Auth} from "aws-amplify";
import Phlebotomy from "../components/Phlebotomy";
import Loading from "../components/Loading";
import SpecimenCount from "../components/SpecimenCount";
import TransportData from "../components/TransportData";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GoogleMaps from "../components/GoogleMaps";
import Button from "react-bootstrap/Button";
import BackButton from "../components/BackButton";
import RouteInformation from "../components/RouteInformation";
import {createBrowserHistory} from "history";
var randomColor = require('randomcolor'); // import the script
let phlebotomyData = [];
let specimenCount = [];
let transportData = [];
let practiceData = {};
let routeData = [];
let practiceCode = null;
let title = {
    fontSize: "14",
    fontStyle: "bold",
    display: "block"
}

let field = {
    fontSize: "10",

}

let infobox = {
    textAlign: "left"
}

function splitAddress(address) {
    return address.split(',').map((item, i) => {
        return <p key={i}>{item}</p>;
    });
}

export default class PracticePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingPhlebotomyData: true,
            fetchingSpecimenData: true,
            fetchingTransportData: true,
            fetchingRouteData: true,
            fetchingPracticeData: true
        }
        this.history = createBrowserHistory();

    }

    async getPracticeData(token) {
        const apiName = 'MedDataApi_DynamoDB';//'medapi';
        const path = '/practices/' + practiceCode;
        const params = {
            headers: {
                Authorization: token
            }
        };
        return await API.get(apiName, path, params);
    };

    async getPhlebotomyData(token) {
        const apiName = 'MedDataApi_DynamoDB';//'medapi';
        const path = '/phlebotomydata';
        const params = {
            headers: {
                Authorization: token
            },
            queryStringParameters: {
                id: practiceCode
            }
        };
        return await API.get(apiName, path, params);
    }

    async getSpecimenCount(token,group) {
        const apiName = 'MedDataApi_DynamoDB';//'medapi';
        const path = '/specimencount';
        const params = {
            headers: {
                Authorization: token
            },
            queryStringParameters: {
                id: practiceCode,
				group : group
            }
        };

        return await API.get(apiName, path, params);
    }

    async getTransportData(token) {
        const apiName = 'MedDataApi_DynamoDB';//'medapi';
        const path = '/transportdata';
        const params = {
            headers: {
                Authorization: token
            },
            queryStringParameters: {
                id: practiceCode
            }
        };
        return await API.get(apiName, path, params);
    }

    async getRouteData(token,group) {
        const apiName = 'MedDataApi_DynamoDB';
        const path = '/routedata';
        const params = {
            headers: {
                Authorization: token
            },
            queryStringParameters: {
                id: practiceCode,
				group : group
            }
        };
        return await API.get(apiName, path, params);
    }
    selectRoute = json => {
        console.log('Select Route ' + JSON.stringify(json));
        this.history.push('/route/' + json.routeId);
    }

    componentDidMount() {
        this.setState({
            fetchingPhlebotomyData: true,
            fetchingSpecimenData: true,
            fetchingTransportData: true,
            fetchingRouteData: true,
            fetchingPracticeData: true
        });


        if (this.props.json && this.props.json.Practice_Code)
            practiceCode = this.props.json.Practice_Code;
        else {
            const { match: { params } } = this.props;
            practiceCode = params.id;
        }

        Auth.currentSession()
            .then(session => {
                const token = session.getIdToken().getJwtToken();
				var groups = session.idToken.payload["cognito:groups"];
                this.getPracticeData(token)
                    .then(data => {
						console.log('practice data :',data);
						if(data.length > 0)
                        practiceData = data[0];
						this.setState({
                             fetchingPracticeData: false							 
                         });
                    })
                    .then(async () => {
						let z = await setTimeout(() => {
                        // this.setState({
                            // fetchingPracticeData: false							 
                        // });
						}, 1000);
                    });
                this.getPhlebotomyData(token)
                    .then(data => {
                        phlebotomyData = data;
                    })
                    .then(async () => {
						let z = await setTimeout(() => {
                        this.setState({
                            fetchingPhlebotomyData: false
                        });
						}, 1000);
                    });
                this.getSpecimenCount(token, groups[0])
                    .then(data => {

                        specimenCount = [["Date", "Specimen Count",{ role: 'style' },{
                            sourceColumn: 0,
                            role: 'annotation',
                            type: 'string',
                            calc: 'stringify',
                        }]];
                        for (let i in data) {
                            let color = randomColor();
                            specimenCount.push([data[i].Request_Date, data[i].SpecimenCount, color, data[i].SpecimenCount])

                        }
//                        specimenCount = data;
                    })
                    .then(async () => {
						let z = await setTimeout(() => {
                        this.setState({
                            fetchingSpecimenData: false
                        })
						}, 1000);
                    });
                // this.getTransportData(token)
                //     .then(data => {
                //         transportData = data;
                //     })
                //     .then(() => {
                //         this.setState({
                //             fetchingTransportData: false
                //         })
                //     });
                this.getRouteData(token,groups[0])
                    .then(data => {
                        routeData = data;
                    })
                    .then(async () => {
						let z = await setTimeout(() => {
                        this.setState({
                            fetchingRouteData: false
                        })
						}, 1000);
                    });
            })
            .catch(err => console.log(err));
    }


    render() {
        return (
            <div className="container">
                {this.state.fetchingPracticeData ? <Loading/> :
                    <div>
                        <Row style={{paddingTop: 20, paddingBottom: 20}}>
                            <Col>
                                <h1 style={infobox}>{practiceData.Practice_Name}</h1>
                            </Col>
                            <Col><BackButton/></Col>
                        </Row>
                        <Row style={infobox}>
                            <Col>
                                <div style={title}><h4>Practice Code</h4></div>
                                <div style={field}><p>{practiceData.Practice_Code}</p></div>
                                <div style={title}><h4>Address</h4></div>
                                <div style={field}>{practiceData.Address}</div>
                                <div style={title}><h4>Phone Number</h4></div>
                                <div style={field}><p>{practiceData.Telephone}</p></div>
                                <div style={title}><h4>Patient Size</h4></div>
                                <div style={field}><p>{practiceData.Patient_Size}</p></div>
                            </Col>
                            <Col>
                                <div className='text-center' style={{padding: 20, paddingLeft: 30}}><GoogleMaps
                                    longitude={practiceData.Longitude} latitude={practiceData.Latitude}/></div>
                            </Col>
                        </Row>
                    </div>
                }
                {this.state.fetchingRouteData ? <Loading/> : <RouteInformation selectRoute={this.selectRoute} practiceId={practiceCode} data={routeData}/>}
                {/*{this.state.fetchingTransportData ? <Loading/> : <TransportData data={transportData}/>}*/}
                {this.state.fetchingPhlebotomyData ? <Loading/> : <Phlebotomy data={phlebotomyData}/>}
                {this.state.fetchingSpecimenData ? <Loading/> : <SpecimenCount data={specimenCount}/>}
            </div>
        );
    }
}
