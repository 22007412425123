import React, { Component } from "react";
import {
    ConfirmSignIn,
    ForgotPassword,
    RequireNewPassword,
    SignIn,
    SignUp,
    VerifyContact,
    withAuthenticator
} from "aws-amplify-react";
import { Auth, API } from "aws-amplify";
import Button from "react-bootstrap/Button";
import Loading from "../components/Loading";
import RoutificProjects from "../components/RoutificProjects";
import BackButton from "../components/BackButton";
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

let projectData = [];

const tabListStyle = {
    fontFamily: "sans-serif",
    textAlign: "center",
    margin: "0 0 0px",
    borderBottom: "0px"

};

const embedDashboard = {
    width: "100%",
    minHeight: "100px",
    borderWidth: "0px",
};


const embedDiv = {
    width: "100%",
    minHeight: "200vh",
    borderWidth: "0px",
    overflow: "hidden",
    frameborder: "0"
    //minWidth: "790px"
};

function canAccessIFrame(iframe) {
    var html = null;
    try {
        // deal with older browsers
        var doc = iframe.contentDocument || iframe.contentWindow.document;
        html = doc.body.innerHTML;
    } catch (err) {
        // do nothing
    }

    return (html !== null);
}


class Routific extends Component {

    constructor(props) {
        super(props);
        console.log('in routific')
        this.state = {
            fetchingProjectData: true,
            user: null,
            pageView: this.props.pageView,
            scale: 1,
            selectedDate: new Date(),
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        if (window.innerWidth < 790) {
            this.setState({ scale: window.innerWidth / 790 })
        }

    }


    componentDidMount() {

        this.setState({
            fetchingProjectData: true,
        });


        if (this.props.user === null || this.props.user == undefined) {
            Auth.currentAuthenticatedUser()
                .then(user => {
                    this.props.setUser(user.username);
                    this.getAllProjects();
                })
                .catch(err => {
                    console.log(err);
                })
        } else this.getAllProjects();



    }

    async getAllProjects() {

        var proj_date = moment(this.state.selectedDate).format("YYYY-MM-DD");
        console.log(proj_date);
        const data = await Auth.currentSession();
        const token = data.getIdToken().getJwtToken();
        const apiName = 'Routific_API';
        const path = '/getroutificprojects?id=' + proj_date;
        const params = {
            headers: {
                Authorization: token
            }
        };

        projectData = [];
        API.get(apiName, path, params)
            .then((response) => {
                projectData = response;
                this.setState({
                    fetchingProjectData: false
                });
            })
    }


    setDate(date) {
        console.log(date);
        this.setState({
            selectedDate: date
        })
    }



    render() {
        const { user } = this.state;
        let scale = this.state.scale || 1;

        var dashboardMinWidth = {
            minWidth: "790px",
            minHeight: "100vh",
            transform: `scale(${scale})`,
            transformOrigin: "top left"
        };

        {/*{this.state.fetchingUrl ? <Loading/> : <div id="dashboard" className={ 'hide-' + this.props.pageView } style={dashboardMinWidth}><iframe style={embedDiv} title="dashboard" id="menuiframe" src={this.props.page} /></div>}*/ }

        return (

            <div className="container">
                <h3>Routific Projects</h3>
                <div className="col-md-12" style={{ float: 'right', padding: '20px 0px' }}><BackButton /></div>
                <div style={{ display: 'flex', whiteSpace: 'nowrap' }} className="col-md-12">
                    <h6>Select project date : </h6>  &nbsp;
                    <div style={{ width: '25%' }}>
                        <DatePicker style={{ float: 'left', width: '30%' }} selected={this.state.selectedDate} onChange={(date) => this.setDate(date)} />
                    </div>
                    <Button onClick={() => { this.getAllProjects(); }}>Search</Button>
                </div>
                <div style={{ float: 'left' }} className="col-md-9">

                </div>
                <br />
                {this.state.fetchingProjectData ? <Loading /> : <RoutificProjects data={projectData} />}
            </div>

        )
    }

}

const myTheme = {
    button: { backgroundColor: '#343a41' }
};

export default withAuthenticator(Routific, false, [
    <SignIn />,
    <SignUp />,
    <ConfirmSignIn />,
    <VerifyContact />,
    <ForgotPassword />,
    <RequireNewPassword />
], null, myTheme);
