import React, {Component} from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useParams,
    useRouteMatch
} from "react-router-dom";
import { createBrowserHistory } from "history";

// It would be nice to have this in the state, but this variable needs to be initialised before the state is declared.
let maxTime = 0;

export default class RouteInformation extends Component {
    constructor(props) {
        super(props);
        // Sort the transport data in the props by day then time.
        // this.sortData();
        this.state = {
            // Group by day and a list of times.
            data: this.props.data,
            practiceId: this.props.practiceId,
            selectRoute: this.props.selectRoute
            // groupedData: this.groupData()
        };
    }

    // sortData = () => {
    //     const sorter = {
    //         'mon': 0,
    //         'tue': 1,
    //         'wed': 2,
    //         'thu': 3,
    //         'fri': 4,
    //         'sat': 5,
    //         'sun': 6
    //     };
    //
    //     this.props.data.sort((a, b) => {
    //         const day1 = a.Collection_Day.toLowerCase();
    //         const day2 = b.Collection_Day.toLowerCase();
    //         const daySort = sorter[day1] - sorter[day2];
    //         return daySort === 0 ? parseInt(a.Collection_Time) - parseInt(b.Collection_Time) : daySort;
    //     })
    // };

    // groupData = () => {
    //     const map = new Map();
    //     this.props.data.forEach(json => {
    //
    //         const key = json.Collection_Day;
    //         const collection = map.get(key);
    //         if (!collection) {
    //             map.set(key, [json.Collection_Time]);
    //         } else {
    //             collection.push(json.Collection_Time);
    //         }
    //
    //         // Update the number of times, purely for styling later.
    //         const newMax = Math.max(maxTime, map.get(key).length);
    //         if (newMax !== maxTime) maxTime = newMax;
    //     });
    //     return map;
    // };

    // dayMapper = day => {
    //     const days = {
    //         'mon': 'Monday',
    //         'tue': 'Tuesday',
    //         'wed': 'Wednesday',
    //         'thu': 'Thursday',
    //         'fri': 'Friday',
    //         'sat': 'Saturday',
    //         'sun': 'Sunday'
    //     };
    //
    //     return days[day.toLowerCase()]
    // };

    // handleClick = json => {
    //     const active = this.state.active;
    //     const newState = active === 'Search' ? 'Results' : 'Search';
    //
    //     this.setState({
    //         active: newState,
    //         json: json
    //     })
    //     this.history.push('/dashboard/' + json.Practice_Code);
    // };

    loadRoute = json => {
        this.props.selectRoute(json);
    }

    render() {
        return (
            <div>
                <h3>Routes</h3>
                {this.props.data.length === 0 ? <h5>No results found</h5> :
                        <Table responsive='sm'>
                            <thead>
                            <tr>
                                <th colSpan={maxTime}>Route Name</th>
                                <th colSpan={maxTime}>Days of Week</th>
                                <th colSpan={maxTime}>Stop Number</th>
                                <th colSpan={maxTime}>Arrival Time</th>
                            </tr>
                            </thead>
                            <tbody>
                                {this.props.data.map(json => {
									
                                    if (this.props.practiceId === json.Practice_Code) {
                                        return (
                                            <tr>
                                                <td>{json.Route_Name}</td>
                                                <td><Link to={`/route/${json.Route_Id}`}>{json.Run}</Link></td>
                                                <td>{json.Stop_Number}</td>
                                                <td>{json.Arrive_Time}</td>
                                            </tr>
                                        )
                                    }else  if (this.props.practiceId === json.practice_code) {
										  return (
                                            <tr>
                                                <td>{json.vist_name}</td>
                                                <td><Link to={`/route/${json.id}`}>{json.days}</Link></td>
                                                <td>{json.stop_no}</td>
                                                <td>{json.arrive_time}</td>
                                            </tr>
                                        )
									}

                                })}
                            </tbody>
                        </Table>
                    }
            </div>
        );
    }
}
