import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import { API, Auth } from "aws-amplify";
import Loading from "../components/Loading";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GoogleRouteMap from "../components/GoogleRouteMap";
import Button from "react-bootstrap/Button";
import { createBrowserHistory } from "history";
import { Link } from "react-router-dom";
import BackButton from "../components/BackButton";


let title = {
    fontSize: "14",
    fontStyle: "bold",
    display: "block"
}

let field = {
    fontSize: "10",
}
let infobox = {
    textAlign: "left"
}


export default class RoutificProjDriver extends Component {

    constructor(props) {
        super(props);
        this.state = {
            driverData: [],
            projectid: '',
            places: [],
            centerlat : 0,
            centrlng :0,
            loadingdata: false
        }
        this.history = createBrowserHistory();

        this.loadingdata = true;
        this.projectid = this.props.location.state;
        // console.log('driverData:',driverData1)    
        // this.state.driverData = driverData1[0]?.solution?.visits;

        //this.loadingdata = false;

    }


    async componentDidMount() {

        const data = await Auth.currentSession();
        const token = data.getIdToken().getJwtToken();
       
        this.setState({
            loadingdata: true
        });

        this.getDriverInfo(token); 
     
    }

     getDriverInfo(token){

         const apiName = 'Routific_API';
        const path = '/getdriverlastseen?id=' + this.projectid;
        const params = {
            headers: {
                Authorization: token
            }
        };

        var projectdriverData = [];
        API.get(apiName, path, params)
        .then((response) => {
            projectdriverData = response;
           
            if (projectdriverData.length > 0) {
                for (var ij = 0; ij < projectdriverData.length; ij++) 
                for (var i = 0; i < projectdriverData[ij]?.vehicle?.routeStatus.length; i++) {
                    var details = projectdriverData[ij]?.vehicle?.routeStatus[i];
                    var name = projectdriverData[ij]?.vehicle?.name;
                    var id = projectdriverData[ij]?.vehicle?.id;
                    var locatn = projectdriverData[ij]?.vehicle?.routeStatus[i].location;
                    var title = "";

                    if(locatn != null)
                   title = 
                 `Location Name : ${locatn?.name}\nDriver Name : ${name}\nAddress : ${locatn?.address}\nStart Time : ${details?.start_time}\nFinish Time : ${details?.finish_time}\nArrival Time : ${details?.arrival_time}\nExpected Arrival Time : ${details?.expected_arrival_time}\nExpected Finish Time : ${details?.expected_finish_time}\nOriginal Start Time : ${details.original_start_time}\nOriginal Finish Time : ${details.original_finish_time}
                   `;

                    if(locatn !=null){
                        var place  = {
                            id:locatn.id,
                            latitude:locatn.lat,
                            longitude :locatn.lng,
                            address :title,
                            route_name: locatn?.name,
                            status :projectdriverData[ij]?.vehicle?.routeStatus[i].status,
                            vehicle_name: name,
                            id_visit_vehicle: id
                        }
                    this.state.places.push(place); 
                   
                    this.state.centerlat = locatn.lat;
                    this.state.centerlng = locatn.lng;
                    }
                }
            }
          //  this.state.driverData = projectdriverData[0]?.visit;
            this.setState({
                loadingdata: false
            });
            
        });
    }

  

    render() {
        return (

            <div className="container">
                <h2>Driver's Location</h2>
                <div style={{ float: 'right', padding: '20px 0px' }}><BackButton /></div>
                {/* {this.state.loadingdata ? <Loading /> : ''} */}
                {!this.state.loadingdata && (this.state.places == null || this.state.places?.length === 0) ? <h5>No results found</h5> :
                    <Table responsive='sm'>
                        <tbody> 
                            <tr >
                                <td>
                                    <div className='text-center' style={{ padding: 20, paddingLeft: 30 }}>
                                    {this.state.loadingdata ? <Loading /> :
                                    <GoogleRouteMap places={this.state.places} centerlat={this.state.centerlat} centerlng={this.state.centerlng}/>
                                    }
                                    </div>
                                </td>
                            </tr>

                        </tbody>

                    </Table>
                 
        }
            </div>
        );
    }
}
