import React, {Component} from "react";
import {API, Auth} from "aws-amplify";
import Loading from "../components/Loading";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GoogleMaps from "../components/GoogleMaps";
import Button from "react-bootstrap/Button";
import {createBrowserHistory} from "history";
import {Link} from "react-router-dom";
import BackButton from "../components/BackButton";

let routeData = [];
let routeId = null;
let title = {
    fontSize: "14",
    fontStyle: "bold",
    display: "block"
}

let field = {
    fontSize: "10",
}
let infobox = {
    textAlign: "left"
}


export default class RoutePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingRouteData: false,
            routeName: "",
            run: ""
        }
        this.history = createBrowserHistory();

    }

    async getRouteData(token, group) {
        const apiName = 'MedDataApi_DynamoDB';//'medapi';
        const path = '/routedetail';
        const params = {
            headers: {
                Authorization: token
            },
            queryStringParameters: {
                id: routeId,
				group:group
            }
        };
        return await API.get(apiName, path, params);
    }

    componentDidMount() {
        this.setState({
            fetchingRouteData: true,
			userGroup : ''
        });
        if (this.props.json && this.props.json.routeId)
            routeId = this.props.routeId;
        else {
            console.log(JSON.stringify(this.props));
            const {match: {params}} = this.props;
            routeId = params.id;
        }

        Auth.currentSession()
            .then(session => {
                const token = session.getIdToken().getJwtToken();
				var groups = session.idToken.payload["cognito:groups"];
				this.setState({
                            userGroup: groups[0]
                        }) 
                this.getRouteData(token,groups[0])
                    .then(data => {
                        routeData = data;
						console.log(routeData); console.log(this.state.userGroup);
						 
							this.setState({routeName:data[0].Route_Name, run: data[0].Run})
						
						
                    })
                    .then(() => {
                        this.setState({
                            fetchingRouteData: false
                        })
                    });
            })
            .catch(err => console.log(err));
    }


    render() {
        return (
            <div className="container">
                {this.state.fetchingRouteData ? <Loading/> :
                    <div style={{padding:50}}>
                        <Row>
                        <Col><h1>{this.state.routeName}</h1>
                            <h4>Run: {this.state.run}</h4></Col>

                        <Col><BackButton/></Col>
                        </Row>
                        <table>
                            <tr style={{paddingTop: 20, paddingBottom: 20}}>
                                <th style={{minWidth:160}}>
                                    <h6>Practice Code</h6>
                                </th>
                                <th style={{minWidth:160}}>
                                    <h6>Practice Name</h6>
                                </th>
                                <th style={{minWidth:160}}>
                                    <h6>Days of Week</h6>
                                </th> 
                                <th style={{minWidth:160}}>
                                    <h6>Stop Number</h6>
                                </th>
                                <th style={{minWidth:160}}>
                                    <h6>Arrival Time</h6>
                                </th>

                            </tr>
                            {routeData.map(json => {
                                return (
								 
                                    <tr>
                                        <td style={{minWidth:160}}>{(json.Practice_Name) ? <Link to={`/dashboard/${json.Practice_Code}`} activeClassName="active">{json.Practice_Code}</Link>
                                        : json.Practice_Name}</td>
                                        <td style={{minWidth:160}}>{json.Practice_Name}</td>
                                        <td style={{minWidth:160}}>{json.Run}</td>
                                        <td style={{minWidth:160}}>{json.Stop_Number}</td>
                                        <td style={{minWidth:160}}>{json.Arrive_Time}</td>
                                    </tr>
									 
									 
								 
                                )
                            })}

                        </table>
                    </div>
                }
            </div>
        );
    }
}
