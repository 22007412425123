import React, {Component} from "react";
import Autosuggest from "react-autosuggest";
import {API, Auth} from "aws-amplify";

let locations = [];

function arrayUnique(array) {
    let foo = new Map();
    for (const tag of array) {
        foo.set(tag.Practice_Code, tag);
    }
    return [...foo.values()];
}

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength === 0) {
        return []
    } else {
		
        const names = locations.filter(location => {
			if(location.Practice_Name == undefined) return false;
			return location.Practice_Name.toLowerCase().indexOf(inputValue) > -1;
		});
        const codes = locations.filter(location => {
			if(location.Practice_Code == undefined) return false;
			return location.Practice_Code.toLowerCase().indexOf(inputValue) > -1;
		});
        const postCodes = locations.filter(location => {
			if(location.Postcode == undefined) return false;
			return location.Postcode.toLowerCase().indexOf(inputValue) > -1;
		});
        return arrayUnique(names.concat(codes, postCodes));
    }

};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.Practice_Code + ' - ' + suggestion.Practice_Name + ' - ' + suggestion.Postcode;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
    <div>
        {suggestion.Practice_Code} - {suggestion.Practice_Name} - {suggestion.Postcode}
    </div>
);

const typeHeader = {
    fontSize:20,
    textAlign: "center",
    paddingBottom: 30
};
class Typeahead extends Component {
    constructor(props) {
        super(props);

        // Get the locations for the typeahead. Constructor called before render.
        this.getLocations();

        // Autosuggest is a controlled component.
        // This means that you need to provide an input value
        // and an onChange handler that updates this value (see below).
        // Suggestions also need to be provided to the Autosuggest,
        // and they are initially empty because the Autosuggest is closed.
        this.state = {
            value: '',
            suggestions: [],
            json: {}
        };
    }

    getLocations = () => {
        const apiName = 'MedDataApi_DynamoDB';//'medapi';
        const path = '/practices';

        Auth.currentSession()
            .then(data => {
                const params = {
                    headers: {
                        Authorization: data.getIdToken().getJwtToken()
                    }
                };
                return API.get(apiName, path, params)

            })
            .then(data => {
                locations = data;
            })
            .catch(err => console.log(err))
    };

    onChange = (event, {newValue}) => {
        this.setState({
            value: newValue
        });

    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({value}) => {
        this.setState({
            suggestions: getSuggestions(value),
            json: getSuggestions(value)[0]
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = (event, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => {
        // Pass data back to parent.
        this.setState(suggestion);
        this.props.callback(suggestion);
    };

    render() {
        const {value, suggestions} = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'Enter Location',
            value,
            onChange: this.onChange
        };

        // Finally, render it!
        return (
            <div className="typeahead">
            <div style={typeHeader}>Please enter Practice Name, Postcode or Practice Id</div>
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={this.onSuggestionSelected}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
            />
            </div>
        );
    }
}

export default Typeahead;