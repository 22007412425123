import React, {Component} from "react";

import {
    ConfirmSignIn,
    ForgotPassword,
    RequireNewPassword,
    SignIn,
    SignUp,
    VerifyContact,
    withAuthenticator
} from "aws-amplify-react";
import {Auth, API} from "aws-amplify";
import Loading from "../components/Loading";
var QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

let url = "";
let dashboardId = '';

const tabListStyle = {
    fontFamily: "sans-serif",
    textAlign: "center",
    margin: "0 0 0px",
    borderBottom: "0px"

};

const embedDashboard = {
    width: "100%",
    minHeight: "100px",
    borderWidth: "0px",
};


const embedDiv = {
    width: "100%",
    minHeight: "200vh",
    borderWidth: "0px",
    overflow: "hidden",
    frameborder: "0"
    //minWidth: "790px"
};

function canAccessIFrame(iframe) {
    var html = null;
    try {
        // deal with older browsers
        var doc = iframe.contentDocument || iframe.contentWindow.document;
        html = doc.body.innerHTML;
    } catch(err){
        // do nothing
    }

    return(html !== null);
}


class KentMedway2 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fetchingUrl: true,
            user: null,
            dashboard: {"EmbedUrl": "../loading.html"},
            pageView: this.props.pageView,
            scale: 1};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);


    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        if (window.innerWidth < 790) {
            this.setState({ scale: window.innerWidth / 790})
        }

    }
    onDashboardLoad = () => {
		console.log('dashboard loaded');
       //  var iframe = document.getElementById("dashboard").firstChild;
        // debugger
        // iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
    }

    onDashboardError = (error) => {
        console.log('error : ', error);
    }

    loadQuicksightDashboard = (dashboard) => {
        var id = 'dashboard';
        var options = {
            url: dashboard.EmbedUrl,
            container: document.getElementById(id),
            scrolling: "no",
            height: "AutoFit",
            loadingHeight: "200px",
            width: "100%",
            footerPaddingEnabled: true
        };
console.log(options);
        document.getElementById(id).innerHTML = '';
        let db = QuickSightEmbedding.embedDashboard(options);
        db.on("error", this.onDashboardError);
        db.on("load", this.onDashboardLoad);
    }

    componentDidMount() {
		
		 
         
		
        if (this.props.user === null || this.props.user == undefined) {
            Auth.currentAuthenticatedUser()
                .then(user => {
                    this.props.setUser(user.username);
                    this.getQuickSightDashboardEmbedURL();
                    //this.getDashboardURL(user.username);
                })
                .catch(err => {
                    console.log(err);
                })
        }else this.getQuickSightDashboardEmbedURL();
        //this.getDashboardURL();


        
    }


    getQuickSightDashboardEmbedURL = async () => {
        const data = await Auth.currentSession();
        const token = data.getIdToken().getJwtToken();
        const payloadSub = data.idToken.payload.sub;
        const email = data.idToken.payload.email; 
		 
		
		const apiName = 'MedDataApi_DynamoDB';
        const path = '/getdashboardembedurl';//'/getdashboardembedurl';getquicksightembedurl
		const params = {          
			headers: {
                Authorization: token
            },
            queryStringParameters: {
                dashboardId: "b4167d6c-105d-4f26-832f-5b84f610161a" 
            }
        }
         
        const quicksight = await API.get(apiName, path, params);
        console.log(quicksight);

       // window.open( quicksight.data.data.EmbedUrl);
        this.loadQuicksightDashboard(quicksight);
        // const containerDiv = document.getElementById("dashboardContainer");
        
        // const options = {
        //     url: quicksight.data.data.EmbedUrl,
        //     container: containerDiv,
        //     parameters: {
        //         country: "United States"
        //     },
        //     scrolling: "no",
        //     height: "800px",
        //     width: "912px",
        //     footerPaddingEnabled: true,
        // };
        // const dashboard = QuickSightEmbedding.embedDashboard(options);
        // this.setState({ loader: false });
    };
 


    render() {
        const { user } = this.state;
        let scale = this.state.scale || 1;

        var dashboardMinWidth = {
            minWidth: "790px",
            minHeight:"100vh",
            transform: `scale(${scale})`,
            transformOrigin: "top left"
        };

        {/*{this.state.fetchingUrl ? <Loading/> : <div id="dashboard" className={ 'hide-' + this.props.pageView } style={dashboardMinWidth}><iframe style={embedDiv} title="dashboard" id="menuiframe" src={this.props.page} /></div>}*/}

        return(

                <div id="dashboard" className={ 'hide-' + this.props.pageView } style={dashboardMinWidth}><iframe style={embedDiv} title="dashboard" id="menuiframe" src={this.props.page} /></div>

        )
    }

}

const myTheme = {
    button: {backgroundColor: '#343a41'}
};

export default withAuthenticator(KentMedway2, false, [
    <SignIn/>,
    <SignUp/>,
    <ConfirmSignIn/>,
    <VerifyContact/>,
    <ForgotPassword/>,
    <RequireNewPassword/>
], null, myTheme);
