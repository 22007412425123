import React, {Component} from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";

// It would be nice to have this in the state, but this variable needs to be initialised before the state is declared.
let maxTime = 0;

export default class TransportData extends Component {
    constructor(props) {
        super(props);
        // Sort the transport data in the props by day then time.
        this.sortData();
        this.state = {
            // Group by day and a list of times.
            groupedData: this.groupData()
        };
    }

    sortData = () => {
        const sorter = {
            'mon': 0,
            'tue': 1,
            'wed': 2,
            'thu': 3,
            'fri': 4,
            'sat': 5,
            'sun': 6
        };

        this.props.data.sort((a, b) => {
            const day1 = a.Collection_Day.toLowerCase();
            const day2 = b.Collection_Day.toLowerCase();
            const daySort = sorter[day1] - sorter[day2];

            return daySort === 0 ? parseInt(a.Collection_Time) - parseInt(b.Collection_Time) : daySort;
        })
    };

    groupData = () => {
        const map = new Map();
        this.props.data.forEach(json => {
            const key = json.Collection_Day;
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [json.Collection_Time]);
            } else {
                collection.push(json.Collection_Time);
            }

            // Update the number of times, purely for styling later.
            const newMax = Math.max(maxTime, map.get(key).length);
            if (newMax !== maxTime) maxTime = newMax;
        });
        return map;
    };

    dayMapper = day => {
        const days = {
            'mon': 'Monday',
            'tue': 'Tuesday',
            'wed': 'Wednesday',
            'thu': 'Thursday',
            'fri': 'Friday',
            'sat': 'Saturday',
            'sun': 'Sunday'
        };

        return days[day.toLowerCase()]
    };

    render() {
        return (
            <Card className="text-center">
                <Card.Header as='h4'>Transport Data</Card.Header>
                <Card.Body>
                    {this.props.data.length === 0 ? <h5>No results found</h5> :
                        <Table responsive='sm'>
                            <thead>
                            <tr>
                                <th/>
                                <th colSpan={maxTime}>Collection Times</th>
                            </tr>
                            </thead>
                            <tbody>
                            {[...this.state.groupedData].map(([key, value]) => {
                                return (
                                    <tr key={key}>
                                        <th>{this.dayMapper(key)}</th>
                                        {value.map(time => {return <td key={key + time}>{time}</td>})}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    }
                </Card.Body>
            </Card>
        );
    }
}