import Table from "react-bootstrap/Table";
import Image from 'react-bootstrap/Image'
import React, { Component } from "react";
import GoogleMapReact from 'google-map-react';
const { 
    v1: uuidv1,
    v4: uuidv4,
  } = require('uuid');

const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%'
}
export default class GoogleRouteMap extends Component {
    constructor(props) {
        super(props);
        // Sort the transport data in the props by day then time.
        // this.sortData();
        this.state = {
            // Group by day and a list of times.
            places: this.props.places,
            centerlat: this.props.centerlat,
            centerlng: this.props.centerlng,
            routes: [],
            route_locations: [],
            selected_route: '',
            map_key : uuidv1(),
            map_key_1 : uuidv1(),
            // groupedData: this.groupData()
        };


        this.state.routes = [...new Set(this.state.places.map(obj => obj.vehicle_name))];
        if (this.state.routes.length > 0) this.state.selected_route = this.state.routes[0];
        this.state.route_locations = this.state.places.filter(place => {
            if (place.vehicle_name == this.state.selected_route) return true;
        });

    }


    renderMarkers = (map, maps) => {

        var markers = [];
        for (var i = 0; i < this.state.route_locations.length; i++) {
            var place = this.state.route_locations[i];

            var marker = new maps.Marker({
                position: { lat: place.latitude, lng: place.longitude },
                map,
                title: place.address,
                icon: place.status == "done" ? 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png' : "http://maps.google.com/mapfiles/ms/icons/red-dot.png"

            });

            markers.push(marker);

            if (i == 0) {
                this.setState({
                    centerlat: place.latitude,
                    centerlng: place.longitude
                })
            }
        }



        return markers;
    };

    selectRoute(route) {
        console.log(route);

        this.state.selected_route = route;
        this.state.route_locations = this.state.places.filter(place => {
            if (place.vehicle_name == this.state.selected_route) return true;
        });
        this.state.map_key = uuidv1();
        this.state.map_key_1 = uuidv1();
        this.forceUpdate(); //reload the map
        console.log(this.state);
    }

    clickIcon(obj) {
        console.log(obj);
    }

    render() {
        return (
            <div className="row">
                <div key={this.state.map_key_1} className="col-md-8" style={{ height: '750px', position: 'relative' }}>

                    <div   style={{ position: "absolute", left: "0", right: "0", top: "0", bottom: "0", width: '700px' }}>
                        <GoogleMapReact
                            key={this.state.map_key}
                            bootstrapURLKeys={{ key: 'AIzaSyB1zfo2QrIW15N3Oo3LOP-PE_1dRG7PCO8' }}
                            defaultCenter={{ lat: this.state.centerlat, lng: this.state.centerlng }}
                            defaultZoom={15}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                            onClick={this.clickIcon}
                        >
                        </GoogleMapReact>
                        {/* <GoogleMapReact
                            key={}
                            bootstrapURLKeys={{ key: 'AIzaSyB1zfo2QrIW15N3Oo3LOP-PE_1dRG7PCO8' }}
                            defaultCenter={{ lat: this.state.centerlat, lng: this.state.centerlng }}
                            defaultZoom={15}
                            yesIWantToUseGoogleMapApiInternals
                            onClick={this.clickIcon}
                        >
                            {this.state.route_locations.map(location => {

                                for (var i = 0; i < this.state.route_locations.length; i++) {
                                    var place = this.state.route_locations[i];

                                    <Marker
                                        lat={place.latitude}
                                        lng={place.longitude}
                                        title={place.address}
                                        icon={place.status == "done" ? 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png' : "http://maps.google.com/mapfiles/ms/icons/red-dot.png"}
                                    />

                                    if (i == 0) {
                                        this.setState({
                                            centerlat: place.latitude,
                                            centerlng: place.longitude
                                        })
                                    }
                                }


                            })}
                        </GoogleMapReact> */}
                    </div>
                </div>
                <div className="col-md-4" style={{ textAlign: 'left', paddingLeft: '10px' }}>
                    <Image src='http://maps.google.com/mapfiles/ms/icons/blue-dot.png' /> - Stop complete
                    <br />
                    <Image src='http://maps.google.com/mapfiles/ms/icons/red-dot.png' /> - Stop not completed
                    <br />
                    <div style={{ maxHeight: '700px', overflowX: 'scroll' }}>
                        <Table responsive='sm' >

                            <thead><tr><th>Routes</th></tr></thead>
                            <tbody>
                                {this.state.routes?.map((json, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td onClick={() => this.selectRoute(json)}
                                            style={{ backgroundColor: this.state.selected_route == json? '#adade8': 'white'}}
                                            >
                                                {json}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>

                        </Table>
                    </div>
                </div>
            </div>
        );
    }
    //};

};

