import React, {Component} from 'react';
import '../App.css';
import AutoSuggest from "../components/Typeahead";
import PracticePage from './PracticePage'
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useParams,
    useRouteMatch
} from "react-router-dom";
import { createBrowserHistory } from "history";
import {
    ConfirmSignIn,
    ForgotPassword,
    RequireNewPassword,
    SignIn,
    VerifyContact,
    withAuthenticator
} from "aws-amplify-react";
import {Auth} from "aws-amplify";

class SearchPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: 'Search',
            json: {},
        };
        this.history = createBrowserHistory();;
        console.log(this.props);
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser()
            .then(user => {
                this.props.setUser(user.username);
            })
            .catch(err => {
                console.log(err);
            })
    }

    handleClick = json => {
        const active = this.state.active;
        const newState = active === 'Search' ? 'Results' : 'Search';

        this.setState({
            active: newState,
            json: json
        })
        this.history.push('/dashboard/' + json.Practice_Code);
    };

    render() {
        const active = this.state.active;
        return (
            <Container>
                {active === 'Search' && <div className='typeahead'><AutoSuggest callback={this.handleClick}/></div>}
                {active === 'Results' && <PracticePage back={this.handleClick} json={this.state.json}/>}
            </Container>

            // <Router>
            //     <Switch>
            //         <Route path="/dashboard" render={(routeProps) => <AutoSuggest callback={this.handleClick}  {...routeProps}/>} />
            //         <Route path="/dashboard/:id">
            //             <Results json={this.state.json} back={this.handleClick}/>
            //         </Route>
            //     </Switch>
            // </Router>


        );
    }
}
{/*<Container>*/}
{/*    {active === 'Search' && <div className='typeahead'><AutoSuggest callback={this.handleClick}/></div>}*/}
{/*    {active === 'Results' && <div className='text-center'><Results json={this.state.json} back={this.handleClick}/></div>}*/}
{/*</Container>*/}

const myTheme = {
    button: {backgroundColor: '#343a40'}
};

export default withAuthenticator(SearchPage, false, [
    <SignIn/>,
    <ConfirmSignIn/>,
    <VerifyContact/>,
    <ForgotPassword/>,
    <RequireNewPassword/>
], null, myTheme);


