import React, {Component} from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Loading from "./Loading";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";

export default class Phlebotomy extends Component {
    render() {
        return (
            <div>
                <h3>Phlebotomy Times</h3>
                {this.props.data.length === 0 ? <h5>No results found</h5> :
                    <Table responsive='sm'>
                        <thead>
                        <tr>
                            <th/>
                            <th>Start Time</th>
                            <th>Finish Time</th>
                        </tr>
                        </thead>

                        {
                            this.props.data.map(json => {
                                return (
                                    <tbody key='dataBody'>

                                    <tr key='Monday'>
                                        <th key='MonHead'>Monday</th>
                                        <td key='MonStart'>{json.monstart}</td>
                                        <td key='MonFin'>{json.monfin}</td>
                                    </tr>
                                    <tr key='Tuesday'>
                                        <th key='TueHead'>Tuesday</th>
                                        <td key='TueStart'>{json.tuestart}</td>
                                        <td key='TueFin'>{json.tuefin}</td>
                                    </tr>
                                    <tr key='Wednesday'>
                                        <th key='WedHead'>Wednesday</th>
                                        <td key='WedStart'>{json.wedstart}</td>
                                        <td key='WedFin'>{json.wedfin}</td>
                                    </tr>
                                    <tr key='Thursday'>
                                        <th key='ThuHead'>Thursday</th>
                                        <td key='ThuStart'>{json.thustart}</td>
                                        <td key='ThuFin'>{json.thufin}</td>
                                    </tr>
                                    <tr key='Friday'>
                                        <th key='FriHead'>Friday</th>
                                        <td key='FriStart'>{json.fristart}</td>
                                        <td key='FriFin'>{json.frifin}</td>
                                    </tr>
                                    <tr key='Saturday'>
                                        <th key='SatHead'>Saturday</th>
                                        <td key='SatStart'>{json.satstart}</td>
                                        <td key='SatFin'>{json.satfin}</td>
                                    </tr>
                                    <tr key='Sunday'>
                                        <th key='SunHead'>Sunday</th>
                                        <td key='SunStart'>{json.sunstart}</td>
                                        <td key='SunFin'>{json.sunfin}</td>
                                    </tr>
                                    </tbody>

                                )
                            })
                        }

                    </Table>
                }
            </div>
        );
    }
}
