import React, { Component } from "react";
import * as XLSX from "xlsx";
import moment from 'moment';
import {
    ConfirmSignIn,
    ForgotPassword,
    RequireNewPassword,
    SignIn,
    SignUp,
    VerifyContact,
    withAuthenticator
} from "aws-amplify-react";
import { Auth, API } from "aws-amplify";
import Loading from "../components/Loading";


const {Readable}  =require("stream");
const regionName = 'eu-west-2';
const bucketName = 's3-dynamodb-date-upload';
const aws = require('aws-sdk');
const s3 = new aws.S3({ apiVersion: '2006-03-01', region:regionName });
const { 
  v1: uuidv1,
  v4: uuidv4,
} = require('uuid');


var QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

let url = "";
let title = {
    fontSize: "14",
    fontStyle: "bold",
    display: "block"
}

let field = {
    fontSize: "10",

}

const tabListStyle = {
    fontFamily: "sans-serif",
    textAlign: "center",
    margin: "0 0 0px",
    borderBottom: "0px"

};

const embedDashboard = {
    width: "100%",
    minHeight: "100px",
    borderWidth: "0px",
};


const embedDiv = {
    width: "100%",
    minHeight: "200vh",
    borderWidth: "0px",
    overflow: "hidden",
    frameborder: "0"
    //minWidth: "790px"
};

function canAccessIFrame(iframe) {
    var html = null;
    try {
        // deal with older browsers
        var doc = iframe.contentDocument || iframe.contentWindow.document;
        html = doc.body.innerHTML;
    } catch (err) {
        // do nothing
    }

    return (html !== null);
}


class ReadableString extends Readable {
  sent = false;
  str ='';

  constructor(input) {
	  super();
	  this.str = input;
    
  }

  _read() {
    if (!this.sent) {
      this.push(Buffer.from(this.str));
      this.sent = true
    }
    else {
      this.push(null)
    }
  }
}


export default class BCPS extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fetchingUrl: true,
            user: null,
            dashboard: { "EmbedUrl": "../loading.html" },
            pageView: this.props.pageView,
            scale: 1,
			selTable : ''
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.handleClick = this.handleClick.bind(this);


    }

    handleClick(e) {
        this.refs.fileUploader.click();
    }

    filePathset(e) {
        e.stopPropagation();
        e.preventDefault();
        var file = e.target.files[0];
        console.log(file);
        this.setState({ file });

        console.log(this.state.file);
    }
	
	
async  batchInsert(params) {
	console.log("Adding a new item based on: ", params);
	try {
		
		var credentials  = new aws.CognitoIdentityCredentials({
             IdentityPoolId: 'eu-west-2:f5f71407-14a9-4488-8e7a-03172a838238',
			 region: regionName
         });
		 aws.config.update({ region: regionName });
		var dynamoDbClient = new aws.DynamoDB({ apiVersion: '2012-08-10',region: regionName,credentials:credentials });
			// aws.config.update({ region: regionName });
			
 
		 aws.config.credentials = credentials;
			//aws.config.update({ region: regionName });
		var res = await dynamoDbClient.batchWriteItem(params).promise();
		console.info('Statement executed successfully.', res);
		// Handle batchExecuteStatementOutput
	} catch (err) {
		console.error("Unable to add item. Error JSON:", err);
	}

}

async  csvToDynamo(fileContent) {
	
	if(this.state.selTable == ''){
		alert("Select table to upload");
		return;
	}
		 const csv = require('csvtojson/v2');
		 var rowArray = [];
	 var convertor = csv({
			noheader: false, 
			trim: true,
			delimiter: ","
		});
	 
	 const jsonArray = await convertor.preFileLine((fileLineString, lineIdx) => {
			fileLineString = fileLineString.replace(/missing/g, '');			

			return fileLineString
		}).fromString(fileContent); 
		
		var jsonContent, row;
		var batchrows = 0;
	  
	for(var i=0; i < jsonArray.length; i++){
		console.log("json content: ", jsonArray[i]);
		jsonContent = jsonArray[i];
		
		console.log(this.state.selTable)
		if(this.state.selTable == 'bcps_baseline_routes'){
			row = this.getBaselineTemplate(jsonContent);
			rowArray.push(row); 
			batchrows = 24;
		} else{
			batchrows = 12;
			row = this.getBaselineTemplate(jsonContent);
			rowArray.push(row); 
		}

		

	 //bcps_baseline_routes
		if ((i!=0 && i % batchrows == 0) || i == jsonArray.length - 1) {
			
				var params = {
			RequestItems: {
				"base_test": rowArray
			},
			ReturnConsumedCapacity: 'NONE',
			ReturnItemCollectionMetrics: 'NONE',

		};
			await this.batchInsert(params);
			rowArray = []; 
		}
	}
	
	 
	 

};


getBaselineTemplate(jsonContent){
	
	return {
			PutRequest: {
				Item: { 
				"br_id":{ S: uuidv1()+ "" }, 
				"practice_code":{ S: jsonContent.practice_code+ "" },
				"hospital_code":{ S: jsonContent.hospital_code+ "" },
				"CCG": { S:jsonContent.CCG+ "" },
				"run_name":{ S: jsonContent.run_name+ "" },
				"location_name":{ S: jsonContent.location_name+ "" },
				"postcode":{ S: jsonContent.postcode+ "" },
				"courier_collection_time":{ S: jsonContent.courier_collection_time+ "" },
				"courier_departure_time": { S:jsonContent.courier_departure_time+ "" },  
				"courier_delivery_lab_time":{ S: jsonContent.courier_delivery_lab_time+ "" },
				"dotw": { N:jsonContent.dotw},
				"collection_address": { S:jsonContent.collection_address+ "" },
				"delivery_address":{ S: jsonContent.delivery_address+ "" },
				"delivery_zip_code":{ S: jsonContent.delivery_zip_code+ "" } 
			}
			}
		}
}	


getRoutesTemplate(jsonContent){
	
	return {
			PutRequest: {
				Item: { 
				"br_id":{ S: uuidv1()+ "" }, 
				"practice_code":{ S: jsonContent.practice_code+ "" },
				"hospital_code":{ S: jsonContent.hospital_code+ "" },
				"CCG": { S:jsonContent.CCG+ "" },
				"run_name":{ S: jsonContent.run_name+ "" },
				"location_name":{ S: jsonContent.location_name+ "" },
				"postcode":{ S: jsonContent.postcode+ "" },
				"courier_collection_time":{ S: jsonContent.courier_collection_time+ "" },
				"courier_departure_time": { S:jsonContent.courier_departure_time+ "" },  
				"courier_delivery_lab_time":{ S: jsonContent.courier_delivery_lab_time+ "" },
				"dotw": { N:jsonContent.dotw},
				"collection_address": { S:jsonContent.collection_address+ "" },
				"delivery_address":{ S: jsonContent.delivery_address+ "" },
				"delivery_zip_code":{ S: jsonContent.delivery_zip_code+ "" } 
			}
			}
		}
}	


    readFile() {
        var f = this.state.file;
       // var name = f.name;
        const reader = new FileReader();
        reader.onload = (evt) => {
            // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
			console.log(bstr);
			this.csvToDynamo(bstr);
		 
			
			
            // const wb = XLSX.read(bstr, { type: "binary" });
            // /* Get first worksheet */
            // const wsname = wb.SheetNames[0];
            // const ws = wb.Sheets[wsname];
            // /* Convert array of arrays */
            // const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            // /* Update state */
            // console.log("Data>>>" + data);// shows that excel data is read
            // var jsonData = this.convertToJson(data); // shows data in json format
			
			
			// const readable = new ReadableString(jsonData);
			// readable.on("data", (stream) => {
				// var fileName = "cvs_upload_" + moment(new Date()).format('YYYY_MM_DD_HH_MM_SS');
				// var params = {Bucket: bucketName, Key: fileName, Body: stream};  
			  // s3.upload(params, function(err, data) {
			  // console.log(err, data);
			// });
			// })
			

        };
        reader.readAsBinaryString(f);
    }

     

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        if (window.innerWidth < 790) {
            this.setState({ scale: window.innerWidth / 790 })
        }

    }
   
   

    componentDidMount() {
		
		//hide menu
		document.getElementById('responsive-navbar-nav').innerHTML = '';
		 

    }

 


    render() {
        const { user } = this.state;
        let scale = this.state.scale || 1;

        var dashboardMinWidth = {
            minWidth: "790px",
            minHeight: "100vh",
            transform: `scale(${scale})`,
            transformOrigin: "top left"
        };

        {/*{this.state.fetchingUrl ? <Loading/> : <div id="dashboard" className={ 'hide-' + this.props.pageView } style={dashboardMinWidth}><iframe style={embedDiv} title="dashboard" id="menuiframe" src={this.props.page} /></div>}*/ }
// value={this.state.selTable} onChange={(e) => this.state.selTable = e.target.value}
        return (

            <div style={{width:'90%', margin: 'auto', paddingTop: '40px'}}>
				<h2 style={{title}}>Upload Data</h2>
					<div style={{padding:'5px'}}>
					  <label>Choose a table:</label>
					  <select name="tables" id="tables" style={{marginLeft:'5px'}} value={this.state.selTable} onChange={(e) => this.state.selTable = e.target.value}>
					  <option value="">Select</option> 
						<option value="bcps_baseline_routes">BCPS Baseline Routes</option> 
						<option value="bcps_baseline_routes">Optimal Routes</option> 
						<option value="bcps_baseline_routes">Shuttle Details</option> 
					  </select>
					</div>
					<div>	
					<input type="file" id="file" ref="fileUploader"  onChange={this.filePathset.bind(this)}/>
					<br/><br/>
                <button onClick={() => { this.readFile(); }}>Upload File</button>
				</div>
            </div>


        )
    }

}
 
